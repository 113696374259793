@import "~@odw/lnc-styles/lib/scss/main";

/**
 * Hide elements visually, but keep them available for screen readers.
 *
 * Used for information required for screen reader users to understand and use
 * the site where visual display is undesirable. Information provided in this
 * manner should be kept concise, to avoid unnecessary burden on the user.
 */
.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  word-wrap: normal;
}

/**
 * The .focusable class extends the .visually-hidden class to allow
 * the element to be focusable when navigated to via the keyboard.
 */
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
  position: static;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
}

// ====================
//       PORTAIL
// ====================

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.main__article,
.main__iframe {
  width: 100%;
  margin-bottom: 20px;
}

.main__article {
  padding-inline: 40px;
}

.maintenance-microapp .indisponibilite-url {
  @include device(tablet, desktop) {
    // Le width inclut le padding interne au lnc-web-illustrative-feedback
    max-width: calc(800px + 2 * 24px);
  }
}

lnc-web-breadcrumbs {
  margin-block: 16px;
}

@include device("mobile") {
  // === Mobile ===
  lnc-web-breadcrumbs {
    margin-block: 8px;
  }

  .main__article {
    padding-inline: 20px;
  }
}

// ====================
//     UTILITAIRES
// ====================

.d-none {
  display: none;
}

neufsneuf-header {
  position: sticky;
  top: 0;
  z-index: 2;
}

#modal-deconnexion,
#modal-annulation-saisie,
#modal-confirmation-suppression,
#modal-generation-cle-chiffrement {
  .modal-dialog {
    max-width: 640px;
    @include device("mobile") {
      width: calc(100vw - 48px);
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .modal-content {
    border-radius: 12px;
  }

  .lnc-modal-container {
    padding: 16px 24px;
    background: $blanc;
    border-radius: 12px;

    .position-button {
      @include device("mobile") {
        flex-direction: column-reverse;
        gap: 16px;
        width: 100%;
      }
    }

    .position-modal-icon {
      align-items: center;
    }

    hr {
      margin-bottom: 16px;
      margin-left: -8px;
      margin-right: -8px;
    }
  }

  .lnc-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .justify-items {
      align-items: center;
    }
  }
}
